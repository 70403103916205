@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'StudioPro-Regular';
  src: url('../fonts/StudioPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'StudioPro-Bold';
  src: url('../fonts/StudioPro-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "StudioPro-Regular","PingFang SC","Microsoft YaHei";
  line-height: 1.2;
  position: relative;
  color: #000;
  background-color: #fff;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.2rem,(md:.28rem,sm:.34rem,xs:.28rem));
}

.font60{
  @include res(font-size,.6rem,$font60);
}
.font70{
  @include res(font-size,.7rem,$font70);
}
.font90{
  @include res(font-size,.9rem,$font90);
}
.font110{
  @include res(font-size,1.1rem,$font110);
}
.common-title{
  font-family: 'StudioPro-Bold';
  @include res(margin-bottom,.7rem);
}


//主体内容部分宽度
.container {
  @include res(padding-left,1.5rem,(md:1rem,xs:.3rem));
  @include res(padding-right,1.5rem,(md:1rem,xs:.3rem));
}
.bg-cover{
  background-repeat: no-repeat;
}
.bold{
  font-family: 'StudioPro-Bold';
}
.ani-line{
  position: relative;
  &::after{
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform: scaleX(0);
    transition: transform .4s;
    transform-origin: right;
    @include res(height,.02rem,(md:2px));
  }
  &:hover{
    &::after{
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.btm-white{
  position: relative;
  &::after{
    content: '';
    display: block;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: -2px;
    left: 0;
    z-index: 1;
    @include res(border-bottom-left-radius,.65rem);
    @include res(border-bottom-right-radius,.65rem);
    @include res(height,.65rem);
  }
}
.top-white{
  position: relative;
  &::after{
    content: '';
    display: block;
    width: 100%;
    background-color: #fff;
    position: absolute;
    bottom: -2px;
    left: 0;
    z-index: 1;
    @include res(border-top-left-radius,.65rem);
    @include res(border-top-right-radius,.65rem);
    @include res(height,.65rem);
  }
}

.banner-t{
  width: 100%;
  .title{
    align-items: center;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-color: #cccccc;
    @include res(display,flex,(xs:block));
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(margin-bottom,.6rem);
    @include res(padding-bottom,.7rem);
    h1{
      // line-height: .8;
      flex-shrink: 0;
      @include res(font-size,1.1rem,$font110);
    }
    .desc{
      flex-shrink: 0;
      color: #4a4a4a;
      line-height: 1.83;
      @include res(font-size, .3rem,(md:.4rem,sm:.5rem,xs:.34rem));
      @include res(width,50%,(xs:100%));
      @include res(margin-top,0,(xs:.5rem));
    }
  }
  .sub-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      @include res(font-size,.22rem,(md:.3rem,sm:.36rem,xs:.3rem));
      a{
        display: inline-block;
        position: relative;
        @include res(padding-left,.25rem);
        @include res(margin-top,.05rem);
        &::after{
          content: '';
          display: block;
          border-radius: 50%;
          background-color: $color-main;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: all .3s;
          opacity: 0;
          @include res(width,.09rem);
          @include res(height,.09rem);
        }
        &:hover,&.active{
          &::after{
            opacity: 1;
          }
        }
      }
    }
  }
  .common-btn{
    &:hover{
      .icon{
        background-color: #000;
        .iconfont{
          // color: $color-main;
        }
      }
    }
    .icon{
      background-color: $color-main;
    }
  }
}

//更多按钮
.common-more{
  display: inline-flex;
  align-items: center;
  border-radius:50px;
  background-color: $color-main;
  position: relative;
  white-space: nowrap;
  transition: all .3s;
  overflow: hidden;
  @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.28rem));
  // @include res(width,1.9rem,(md:2.8rem,sm:3.6rem,xs:2.8rem));
  @include res(width,.53rem,(md:.8rem,sm:1rem,xs:.8rem));
  @include res(height,.53rem,(md:.8rem,sm:1rem,xs:.8rem));
  .t1{
    color: #fff;
    opacity: 0;
    visibility: none;
    transition: all .3s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include res(right,.65rem,(md:.9rem,sm:1.1rem,xs:1rem));
  }
  .btn-circle{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    display: flex;
    align-items: center;
    background: transparent;
    border-radius:50px;
    overflow: hidden;
    transition: clip-path .65s $anime-bezier,transform .65s $anime-bezier,background .3s $anime-bezier;
    @include res(clip-path,inset(0.04rem 0.04rem 0.04rem calc(100% - 0.5rem) round .5rem),(
      md: inset(.08rem .08rem .08rem calc(100% - .76rem) round .5rem),
      sm: inset(.1rem .1rem .1rem calc(100% - .96rem) round .5rem),
      xs: inset(.08rem .08rem .08rem calc(100% - .75rem) round .5rem),
    ));
    .t2{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #fff;
      span{
        opacity: 0;
        visibility: none;
        transition: all .3s;
        position: absolute;
        top: 50%;
        color: #000;
        transform: translateY(-50%);
        @include res(right,.65rem,(md:.9rem,sm:1.1rem,xs:1rem));
      }
      .icon{
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @include res(right,.18rem,(md:.28rem,sm:.3rem,xs:.26rem));
        &::before,&::after{
          content: '\e624';
          font-family: 'iconfont';
          display: inline-block;
          transition: transform .5s $anime-bezier,color .3s;
          @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
        }
        &::before{
          position: absolute;
          top: 0;
          right: 100%;
        }
      }
    }
  }
  &.active{
    @include res(width,1.9rem,(md:2.8rem,sm:3.6rem,xs:2.8rem));
    .t1{
      opacity: 1;
      visibility: visible;
      transition-delay: .2s;
    }
    .btn-circle{
      background: #fff;
      .t2{
        color: $color-main;
        span{
          opacity: 1;
          visibility: visible;
          transition-delay: .2s;
        }
      }
    }
  }
  &:hover{
    .btn-circle{
      @include res(clip-path,inset(.02rem round .5rem),(md:inset(.04rem round .5rem),sm:inset(.04rem round .5rem)));
      .t2{
        .icon{
          &::before,&::after{
            transform: translateX(100%);
          }
        }
      }
    }
  }
}

.common-btn{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &:hover{
    .icon{
      background-color: $color-main;
      .iconfont{
        transform: translateX(100%);
      }
    }
  }
  .icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    transform-origin: center;
    transform: rotate(90deg);
    transition: background-color .3s $anime-bezier,;
    overflow: hidden;
    @include res(width,.47rem,(md:.6rem,sm:.8rem,xs:.7rem));
    @include res(height,.47rem,(md:.6rem,sm:.8rem,xs:.7rem));
    .iconfont{
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      transition: transform .3s $anime-bezier,color .3s $anime-bezier;
      @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.26rem));
    }
  }
  span{
    color: #000;
    @include res(margin-top,.1rem);
    @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
  }
}

.common-form{
  .form-item{
    &:not(:first-child){
      @include res(margin-top,.5rem);
    }
    label{
      display: block;
      @include res(margin-bottom,.2rem);
      span{
        color: $color-main;
      }
    }
    input,textarea{
      display: block;
      background-color: #f0f0f3;
      border: none;
      width: 100%;
      border-style: solid;
      border-color: #f0f0f3;
      transition: all .3s;
      @include res(border-width,.01rem,(md:1px));
      @include res(padding-left,.3rem);
      @include res(padding-right,.3rem);
      @include res(font-size,.2rem,(md:.28rem,sm:.34rem,xs:.28rem));
      &:focus{
        box-shadow: 0 0 .2rem rgba(0,0,0,.1);
        border-color: $color-main;
      }
    }
    input{
      border-radius: 50px;
      @include res(height,.75rem,(md:1rem,sm:1.3rem,xs:1rem));
    }
    textarea{
      line-height: 1.6;
      border-radius: .2rem;
      @include res(padding-top,.2rem);
      @include res(padding-bottom,.2rem);
    }
  }
  .form-btn{
    @include res(margin-top,.3rem);
    button{
      position: relative;
      background-color: $color-main;
      color: #fff;
      display: block;
      width: 100%;
      border: none;
      font-family: 'StudioPro-Bold';
      transition: all .3s;
      border-radius: 50px;
      cursor: pointer;
      @include res(padding-left,.2rem);
      @include res(height,.75rem,(md:1rem,sm:1.3rem,xs:1rem));
      @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.4rem));
      .iconfont{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        color: $color-main;
        @include res(font-size,.32rem,(md:.44rem,sm:.6rem,xs:.5rem));
        @include res(left,.06rem,(md:.1rem,xs:.06rem));
        @include res(width,.63rem,(md:.8rem,sm:1.05rem,xs:.84rem));
        @include res(height,.63rem,(md:.8rem,sm:1.05rem,xs:.84rem));
      }
      &:hover{
        background-color: #a50c1e;
      }
    }
  }
}

.common-search-form{
  .fixed-cont{
    top: 0;
    left: 0;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    transition: opacity .3s,visibility .3s;
    @include res(width,null,(sm:100%));
    @include res(height,null,(sm:100%));
    @include res(display,block,(sm:flex));
    @include res(opacity,1,(sm:0));
    @include res(visibility,visible,(sm:hidden));
    @include res(background,null,(sm:rgba(0,0,0,.5)));
    @include res(position,static,(sm:fixed));
    &.show{
      opacity: 1;
      visibility: visible;
    }
  }
  .form{
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include res(background,null,(sm:#fff));
    @include res(display,flex,(sm:block));
    @include res(padding,0,(sm:10vw 5vw));
    @include res(border-radius,0,(sm:.3rem));
    .mob-close-btn{
      position: absolute;
      z-index: 3;
      @include res(top,.5rem,(xs:.2rem));
      @include res(right,.5rem,(xs:.3rem));
      @include res(display,none,(sm:block));
      .iconfont{
        @include res(font-size,.5rem);
      }
    }
    .form-select{
      flex-shrink: 0;
      position: relative;
      cursor: pointer;
      @include res(width,19.8%,(sm:50vw,xs:80vw));
      &.delkey26{
        @include res(width,26.4%,(sm:50vw,xs:80vw));
        ul{
          background-color: #fff;
          @include res(max-height,3.06rem,(md:4.06rem,sm:5.06rem,xs:4rem));
          li{
            @include res(padding,.02rem .15rem,(xs:.04rem .15rem));
            &:hover{
              background-color: #c40d23;
              color: #fff;
            }
            &.active{
              background-color: #c40d23;
              color: #fff;
            }
          }
        }
      }
      @include res(margin-bottom,0,(sm:.4rem,xs:.2rem));
      .select-title{
        border-bottom: solid #000000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include res(border-bottom-width,.01rem,(md:1px));
        @include res(padding-top,.15rem,(sm:.3rem));
        @include res(padding-bottom,.15rem,(sm:.3rem));
        .text{
          position: relative;
          @include res(max-width,75%);
          p{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          span{
            position: absolute;
            left: calc(100% + .05rem);
            color: $color-desc;
            @include res(top,-.05rem,(md:-.1rem));
            @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.24rem));
          }
        }
        .icon{
          flex-shrink: 0;
          transition: all .3s;
          @include res(margin-right,.1rem);
          @include res(margin-left,.1rem);
          .iconfont{
            @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.3rem));
          }
        }
      }
      ul{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 2;
        background-color: rgba(255,255,255,.5);
        box-shadow: 0 0 .1rem rgba(0,0,0,.1);
        backdrop-filter: blur(10px);
        overflow-y: auto;
        border-bottom-left-radius: .1rem;
        border-bottom-right-radius: .1rem;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        transform: translateY(.1rem);
        transition: all .3s;
        @include res(max-height,4rem);
        &::-webkit-scrollbar {
          width: 4px;    
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          background: rgba(#BDBDBD,.6);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0,0,0,0);
          border-radius: 0;
          background: rgba(0,0,0,0);
        }
        li{
          color: $color-desc;
          transition: all .3s;
          @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
          @include res(padding,.07rem .15rem);
          &:hover{
            color: #000;
          }
        }
      }
      &.active{
        .select-title{
          .icon{
            transform: translateX(.1rem);
          }
        }
        ul{
          pointer-events: all;
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }
    }
    .form-input{
      flex-shrink: 0;
      position: relative;
      @include res(width,19.8%,(sm:50vw,xs:80vw));
      @include res(margin-bottom,0,(sm:.4rem,xs:.2rem));
      input{
        border: none;
        border-bottom: solid #000000;
        display: block;
        width: 100%;
        @include res(font-size,.2rem,(md:.28rem,sm:.34rem,xs:.28rem));
        @include res(border-bottom-width,.01rem,(md:1px));
        @include res(padding-top,.16rem,(md:.18rem,sm:.3rem));
        @include res(padding-bottom,.16rem,(md:.18rem,sm:.3rem));
      }
    }
    .form-btn{
      flex-shrink: 0;
      @include res(text-align,null,(sm:center));
    }
  }
  .mob-open-btn{
    text-align: right;
    @include res(display,none,(sm:block));
  }
  .common-more{
    .t1{
      @include res(right,.75rem,(md:.9rem,sm:1.1rem,xs:1rem));
    }
    .btn-circle{
      .t2{
        span{
          @include res(right,.75rem,(md:.9rem,sm:1.1rem,xs:1rem));
        }
        .icon{
          &::before,&::after{
            content: '\e840';
            color: #000000;
            @include res(font-size,.22rem,(md:.34rem,sm:.44rem,xs:.34rem));
          }
        }
      }
    }
    &.search-btn{
      border: none;
      cursor: pointer;
      @include res(font-size,.16rem,(md:.24rem,sm:.3rem,xs:.24rem));
    }
    &.active{
      @include res(width,2.3rem,(md:3rem,sm:3.8rem,xs:3.2rem));
    }
    &:hover{
      .btn-circle{
        .t2{
          .icon{
            &::before,&::after{
              color: $color-main;
              transform: translateX(0%);
            }
          }
        }
      }
    }
  }
}

//新闻列表
.news-list{
  li{
    border-bottom: solid #cccccc;
    @include res(border-bottom-width,.01rem,(md:1px));
    &:first-child{
      border-top: solid #cccccc;
      @include res(border-top-width,.01rem,(md:1px));
    }
    a{
      position: relative;
      align-items: center;
      justify-content: flex-end;
      transition: padding-right .3s;
      @include res(display,flex,(xs:block));
      @include res(padding-top,.3rem,(xs:.1rem));
      @include res(padding-bottom,.3rem);
      .item-img{
        @include res(margin-bottom,0,(xs:.3rem));
        b{
          display: block;
          left: 0;
          z-index: -1;
          transition: all .3s;
          @include res(position,absolute,(xs:static));
          &.small{
            top: 0%;
            @include res(width,20%,(sm:30%,xs:100%));
            @include res(height,100%,(xs:54vw));
          }
          &.big{
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @include res(display,block,(xs:none));
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-color: rgba(0,0,0,.3);
              position: absolute;
              top: 0;
              left: 0;
              backdrop-filter: blur(10px);
            }
          }
        }
      }
      .item-tag{
        position: absolute;
        background-color: #e6e6e6;
        border-radius: 50px;
        color: #000;
        @include res(padding,.1rem .2rem);
        @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
        @include res(left,.1rem);
        @include res(top,.3rem);
      }
      .item-title{
        flex-shrink: 0;
        transition: all .3s $anime-bezier;
        color: #000;
        @include res(width,35%,(md:33%,xs:100%));
        @include res(margin-right,.5rem);
        @include res(margin-bottom,0,(xs:.3rem));
        .title{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          line-height: 1.25;
          @include res(margin-bottom,.1rem);
          @include res(font-size,.32rem,(md:.42rem,sm:.52rem,xs:.34rem));
        }
        .date{
          @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
        }
      }
      .item-desc{
        flex-shrink: 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        transition: all .3s $anime-bezier;
        @include res(width,32%,(md:30%,sm:20%,xs:100%));
        @include res(margin-right,-.6rem,(md:.3rem));
      }
      .item-link{
        transition: all .3s $anime-bezier;
        @include res(display,null,(xs:none));
        @include res(opacity,0,(md:1));
      }
    }
    &:hover{
      a{
        @include res(padding-right,.1rem,(xs:0));
      }
      .item-img{
        b{
          &.small{
            @include res(opacity,0,(xs:1));
          }
          &.big{
            opacity: 1;
          }
        }
      }
      .item-title{
        transform: translateX(-.8rem,(md:0));
        @include res(color,#fff,(xs:#000));
      }
      .item-desc{
        transform: translateX(-.8rem,(md:0));
        @include res(margin-right,0rem,(md:.3rem));
        @include res(color,#fff,(xs:#333));
      }
      .item-link{
        opacity: 1;
        .common-more{
          @include res(width,1.9rem,(md:2.8rem,sm:3.6rem,xs:2.8rem));
          .t1{
            opacity: 1;
            visibility: visible;
            transition-delay: .2s;
          }
          .btn-circle{
            background: #fff;
            .t2{
              color: $color-main;
              span{
                opacity: 1;
                visibility: visible;
                transition-delay: .2s;
              }
            }
          }
        }
      }
    }
  }
}

//产品列表
.pro-list{
  ul{
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include res(display,flex,(xs:block));
    @include res(width,calc(100% + .15rem),(xs:100%));
    li{
      position: relative;
      background-color: #f0f0f3;
      border-radius: .1rem;
      overflow: hidden;
      @include res(width,calc(33.33% - .15rem),(sm:calc(50% - .15rem),xs:100%));
      @include res(margin-right,.15rem);
      @include res(margin-bottom,.15rem);
      @include res(height,18.23vw,(md:22vw,sm:28vw,xs:59vw));
      @include res(padding-top,.5rem);
      @include res(padding-left,.45rem);
      @include res(padding-right,.45rem);
      .item-title{
        @include res(font-size,.34rem,(md:.44rem,sm:.54rem,xs:.4rem));
        @include res(margin-bottom,.5rem);
      }
      .item-img{
        img{
          display: block;
          width: auto;
          @include res(height,22vw,(md:24vw,sm:33vw,xs:65vw));
        }
      }
      .item-link{
        position: absolute;
        @include res(right,.35rem);
        @include res(bottom,.25rem);
        .common-more{
          background-color: #cfcfcf;
          .btn-circle{
            .t2{
              .icon{
                &::before,&::after{
                  color: #fff;
                }
              }
            }
          }
        }
      }
      &:hover{
        .item-link{
          .common-more{
            background-color: $color-main;
            @include res(width,1.9rem,(md:2.8rem,sm:3.6rem,xs:2.8rem));
            .t1{
              opacity: 1;
              visibility: visible;
              transition-delay: .2s;
            }
            .btn-circle{
              background: #fff;
              .t2{
                color: $color-main;
                span{
                  opacity: 1;
                  visibility: visible;
                  transition-delay: .2s;
                }
                .icon{
                  &::before,&::after{
                    color: $color-main;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.red{
  color: $color-main;
}