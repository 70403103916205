@import "../../assets/styles/utils";

header{
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s $anime-bezier;
    @include res(top,0);
    @include res(height,1.6rem,(md:1.4rem,sm:1.6rem,xs:1.1rem));
    .header-bg{
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      background-color: #fff;
      opacity: 0;
      transition: all .5s;
      @include res(border-bottom-left-radius,.65rem);
      @include res(border-bottom-right-radius,.65rem);
      @include res(height,100%);
    }
    &.trans{
      transform: translateY(-100%);
      @include res(height,$header-height-base,$header-height-ratio);
    }
    &.show-bg{
      @include res(height,$header-height-base,$header-height-ratio);
    }
    &.show-bg,&:hover{
      .header-bg{
        box-shadow: 0 0 .1rem rgba(#000,.1);
        opacity: 1;
      }
      .logo{
        img{
          &.logo-white{opacity: 0}
          &.logo-black{opacity: 1}
        }
      }
      .right{
        .nav ul li .nav-title{
          color: #000;
        }
        .language{
          color: #000;
        }
        .open-search{
          background-color: #b2b2b2;
        }
        .social{
          color:#b2b2b2;
        }
        .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
          background-color: #b2b2b2;
        }
      }
    }
    .logo-list{
      display: flex;
      height: 100%;
      align-items: center;
    }
    .logo{
      position: relative;
      img{
        display: block;
        width: auto;
        transition: all .4s $anime-bezier;
        @include res(height,.45rem,(md:.55rem,sm:.65rem,xs:.4rem));
        &.logo-black{
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
      &:not(:first-child){
        @include res(margin-left, .3rem,(xs:.1rem)); 
      }
    }
    .right{
      align-items: center;
      height: 100%;
      @include res(display,flex);
      .nav{
        height: 100%;
        @include res(display,block,(md:none));
        ul{
          height: 100%;
          align-items: stretch;
          justify-content: flex-end;
          @include res(display,flex);
          li{
            height: 100%;
            position: relative;
            @include res(margin-right,.15rem);
            .nav-title{
              height: 100%;
              color: #fff;
              transition: color .4s $anime-bezier;
              @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.32rem));
              a{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include res(padding-left,.2rem);
                @include res(padding-right,.2rem);
              }
            }
            .sub-nav{
              position: absolute;
              top: 90%;
              left: 0;
              transition: all .3s;
              transform: translateY(-.2rem);
              opacity: 0;
              visibility: hidden;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              @include res(padding-left,.2rem);
              @include res(padding-right,.2rem);
              a{
                white-space: nowrap;
                color: #000;
                @include res(margin-bottom,.2rem);
                &::after{
                  background-color: $color-main;
                }
              }
            }
            &.show-sub{
              .sub-nav{
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition-delay: .2s;
              }
            }
          }
        }
      }
      .language{
        color: #fff;
        transition: color .3s;
        &:hover{
          color: $color-main;
        }
      }
      .open-search{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(255,255,255,.3);
        color: #fff;
        cursor: pointer;
        transition: background-color .4s $anime-bezier;
        @include res(margin-right,.15rem,(md:.2rem));
        @include res(margin-left,.3rem,(md:.2rem));
        @include res(width,.54rem,(md:.7rem,sm:.9rem,xs:.8rem));
        @include res(height,.54rem,(md:.7rem,sm:.9rem,xs:.8rem));
        .iconfont{
          @include res(font-size,.22rem,(md:.3rem,sm:.4rem,xs:.36rem));
        }
        &:hover{
          background-color: $color-main;
        }
      }
      .social{
        color: #dddfe1;
        display: flex;
        align-items: center;
        @include res(margin-right,0,(md:.2rem));
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color .3s $anime-bezier;
          @include res(width,.35rem,(md:.45rem,sm:.55rem,xs:.45rem));
          @include res(height,.54rem,(md:.7rem,sm:.9rem,xs:.8rem));
          &:hover{
            color: $color-main;
          }
        }
        .iconfont{
          @include res(font-size,.3rem,(md:.4rem,sm:.5rem,xs:.4rem));
        }
      }
      .hamburger{
        @include res(display,none,(md:block));
      }
    }
  }
  .header-search{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: $anime-bezier $anime-duration;
    transform: translateY(-100%);
    @include res(padding-left,5rem,(md:5rem,sm:6rem,xs:.3rem));
    @include res(border-bottom-left-radius,.65rem);
    @include res(border-bottom-right-radius,.65rem);
    @include res(height,1.6rem,(md:1.4rem,sm:1.6rem,xs:1.1rem));
    &.active{
      transform: translateY(-.01rem);
      box-shadow: 0 0 .1rem rgba(#000,.1);
    }
    &.show-bg{
      @include res(height,$header-height-base,$header-height-ratio);
    }
    .header-search-logo{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include res(left,1.5rem,(md:1rem,xs:.3rem));
      @include res(display,block,(xs:none));
      img{
        display: block;
        height: auto;
        transition: $anime-bezier $anime-duration;
        @include res(width,3.04rem,(md:3.5rem,sm:4rem,xs:3.04rem));
      }
    }
    form{
      flex-grow: 1;
      display: flex;
      align-items: center;
      input{
        flex-grow: 1;
        border: none;
        @include res(height,.5rem);
        @include res(font-size, .18rem,(md:16px));
      }
      button{
        background-color: #e4e4e4;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: all .4s;
        @include res(margin-left,.1rem);
        @include res(width,.4rem,(md:40px));
        @include res(height,.4rem,(md:40px));
        .iconfont{
          @include res(font-size,.2rem,(md:20px));
        }
        &:hover{
          background-color: $color-main;
          color: #fff;
        }
      }
    }
    .header-search-close{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-weight: bold;
      color: #000;
      cursor: pointer;
      margin-left: .1rem;
      @include res(width, .5rem,(md:50px));
      @include res(height,.5rem,(md:50px));
      &:hover{
        .iconfont{
          transform: rotate(90deg);
        }
      }
      .iconfont{
        display: inline-block;
        transition: all .4s;
      }
    }
  }
  .mob-header{
    position: fixed;
    bottom: 100%;
    right: 0;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: rgba(30,30,30,.7);
    backdrop-filter: blur(10px);
    transition: all .4s $anime-bezier;
    @include res(padding-top, 3rem,(xs:1.3rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.5rem));
    @include res(padding-right, 1rem,(xs:.5rem));
    @include res(width,100%);
    &.show{
      transform: translateY(100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        color: #fff;
        border-bottom: 1px solid rgba(255,255,255,.1);
        letter-spacing: .01rem;
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          @include res(line-height,1.4rem,(xs:1rem));
          @include res(padding-left,.3rem);
          @include res(display,flex);
          @include res(font-size,.48rem,(xs:.36rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.4rem,(xs:.34rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, .6rem);
          @include res(padding-right, .6rem);
          @include res(padding-bottom,.3rem);
          a{
            display: block;
            &:not(:last-child){
              margin-bottom: .2rem;
            }
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}