@import "../../assets/styles/utils";

footer{
  background-color: #1d1e21;
  @include res(text-align,null,(xs:center));
  @include res(padding-top,1.5rem);
  @include res(margin-top,1rem,(xs:.5rem));
  .footer{
    .footer-logo{
      @include res(margin-bottom,.45rem);
      img{
        width: auto;
        @include res(height,.45rem,(md:.55rem,sm:.65rem,xs:.4rem));
        &:not(:first-child){
          @include res(margin-left, .3rem,(xs:.1rem)); 
        }
      }
    }
    .footer-info{
      align-items: flex-start;
      justify-content: space-between;
      color: #fff;
      @include res(display,flex,(xs:block));
      .footer-nav{
        @include res(font-size,.3rem,(md:.4rem,sm:.5rem,xs:.36rem));
        >div{
          @include res(margin-bottom,.05rem);
        }
      }
      .footer-contact{
        @include res(margin-top,0,(xs:.5rem));
        @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
        p{
          @include res(margin-bottom,.07rem);
        }
      }
      .footer-social{
        @include res(margin-top,0,(xs:.5rem));
        @include res(padding-right,.7rem,(md:0));
        @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
        .icon{
          display: flex;
          align-items: center;
          @include res(justify-content,flex-start,(xs:center));
          @include res(margin-bottom,.35rem);
          a{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #fff;
            color: #525252;
            transition: all .3s;
            @include res(width,.5rem,(md:.7rem,sm:.9rem,xs:.8rem));
            @include res(height,.5rem,(md:.7rem,sm:.9rem,xs:.8rem));
            &:not(:last-child){
              @include res(margin-right,.3rem);
            }
            .iconfont{
              @include res(font-size,.3rem,(md:.45rem,sm:.65rem,xs:.5rem));
            }
            &:hover{
              background-color: $color-main;
              color: #fff;
            }
          }
        }
        .link{
          display: flex;
          align-items: center;
          @include res(justify-content,flex-start,(xs:center));
          a{
            &:not(:last-child){
              @include res(margin-right,.3rem);
            }
          }
        }
        .ewm-img{
          display: flex;
          @include res(justify-content,null,(xs:center));
          @include res(margin-bottom,.35rem);
          .item{
            text-align: center;
            @include res(font-size, .14rem,(md:.22rem,sm:.3rem,xs:.24rem));
            &:not(:last-child){
              @include res(margin-right,.35rem);
            }
            img{
              height: auto;
              @include res(width, 1rem,(md:1.5rem,sm:2rem));
              @include res(margin-bottom,.05rem);
            }
          }
        }
      }
      .footer-info-title{
        @include res(margin-bottom,.3rem);
      }
    }
  }
  .copyright{
    border-top: solid rgba(195,195,195,.2);
    color: rgba(255,255,255,.6);
    align-items: center;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    @include res(border-top-width,.01rem,(md:1px));
    @include res(font-size,.18rem,(md:.24rem,sm:.3rem,xs:.24rem));
    @include res(padding-top,.2rem);
    @include res(padding-bottom,.2rem);
    @include res(margin-top,1.2rem,(xs:.5rem));
    a{
      transition: all .3s;
      &:hover{
        color: #fff;
      }
    }
    .right{
      a{
        @include res(margin-left,.05rem);
        @include res(margin-right,.05rem);
      }
      .site-map{
        @include res(display,null,(md:none));
      }
    }
  }
}
.cookie-consent{
  border: 1px solid #fabcbc;
  background: #FFF;
  padding: .3rem .3rem .25rem .3rem;
  max-width: 430px;
  width: calc(100% - 20px);
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 500;
  border-radius: .2rem;
  &.show{
    display: block;
  }
  &.hide{
    display: none;
  }
  p{
    @include res(font-size,.16rem,(xs:14px));
    margin-bottom: .2rem;
    line-height: 1.5;
    a{
      color: #c90d23;
      text-decoration: underline;
    }
  }
  .btn-container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a.button{
      color: #fff;
      padding: .08rem .2rem;
      background-color: #c90d23;
      border-radius: .3rem;
      transition: all 0.3s ease-out;
      @include res(font-size,.16rem,(xs:14px));
      &.reject{
        margin-right: .3rem;
      }
      &:hover{
        background-color: #e73a4e;
      }
    }
  }
}