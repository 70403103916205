@font-face {
  font-family: "iconfont"; /* Project id 4456230 */
  src: url('iconfont.woff2?t=1738827142900') format('woff2'),
       url('iconfont.woff?t=1738827142900') format('woff'),
       url('iconfont.ttf?t=1738827142900') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shipinbofang-:before {
  content: "\e693";
}

.icon-yuedengyu:before {
  content: "\e623";
}

.icon-lijijuankuan:before {
  content: "\e60a";
}

.icon-gangwei:before {
  content: "\e658";
}

.icon-eryanghuatan:before {
  content: "\e7e6";
}

.icon-xiajiang:before {
  content: "\e62f";
}

.icon-twiter:before {
  content: "\e75c";
}

.icon-Feedback:before {
  content: "\e603";
}

.icon-fanhui:before {
  content: "\e62b";
}

.icon-tijiao:before {
  content: "\e714";
}

.icon-small-right:before {
  content: "\e600";
}

.icon-small-left:before {
  content: "\e601";
}

.icon-guanbi:before {
  content: "\e614";
}

.icon-facebook:before {
  content: "\e630";
}

.icon-youjiantou:before {
  content: "\e624";
}

.icon-zuojiantou:before {
  content: "\e625";
}

.icon-jia:before {
  content: "\e632";
}

.icon-icon_diqu:before {
  content: "\e753";
}

.icon-lingying:before {
  content: "\ee48";
}

.icon-search:before {
  content: "\e840";
}

